import jump from "jump.js";

var item_id = "";
var item_value = 0;

function getCalendar(container, locale, selected_date = '', current_month = ''){
    fetch('/' + locale + '/calendar?selected_date=' + selected_date + '&current_month=' + current_month + '&container=' + container + '&product_id=' + $('#product_id').val())
        .then(function (response) {
            return response.json();
        })
        .then(function(data) {
            $(container).html(data.result);
        });
}

// $( document ).ready fires only once, when page is loaded initially.
// Use this block to create event listeners.
$( document ).ready(function() {

    let setCookieTimer = false;

    document.addEventListener('scroll', function(e) {
        if($("#cookie_message").length && setCookieTimer === false){
            setCookieTimer = true;
            setTimeout(function() {
                $("#cookie_message").remove();
            }, 3000);
        }

    });

    // step 1: select size
    $(document).on("click", ".variant-radio-button", function(){
        // send FB event
        // content_category, content_name, currency, value
        if(typeof fbq != "undefined") {
            fbq('track', 'Lead', { content_name: $(this).data("name"), currency: "EUR", value: $(this).data("value") });
        }

        item_id = $('#product_id').val();
        item_value = $(this).data("value");

        // collapse
        $('#previewSizeCaption').html($(this).data("size") + ' ' + $(this).data("price"));
        $('#collapseSize').collapse('hide');
        $('#previewSize').collapse('show');

        // enable next section
        if($("#step2").hasClass("d-none")){
            $("#step2-spinner").removeClass("d-none");
            jump('#step1', {
                callback: () => {
                    $("#step2-spinner").remove();
                    $("#step2").removeClass("d-none").addClass("d-block");
                }
            })
        }
    });
    $(document).on('click', '#previewSize', function(){
        $('#collapseSize').collapse('show');
        $('#previewSize').collapse('hide');
    });

    // step 2: click on date
    $(document).on("click", ".calendar-button-day", function(){
        let caption = $('#previewDateCaption');
        const date = new Date($(this).data("date"));
        const options = {weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

        // change active date
        $('.cl-day').removeClass('cl-day-checked');
        $(this).addClass('cl-day-checked');

        // update caption on review
        $('#previewDate').data('date', $(this).data("date"));
        caption.html(date.toLocaleDateString('lv-LV', options));

        // fill form
        $('#delivery_date').val($(this).data("date"));

        // collapse
        $('#collapseDate').collapse('hide');
        $('#previewDate').collapse('show');

        // enable next session
        if($("#step3").hasClass("d-none")){
            $("#step3-spinner").removeClass("d-none");
            jump('#step2', {
                callback: () => {
                    $("#step3-spinner").remove();
                    $("#step3").removeClass("d-none").addClass("d-block");
                }
            })
        }
    });
    $(document).on('click', '#previewDate', function(){
        $('#collapseDate').collapse('show');
        $('#previewDate').collapse('hide');
    });

    // step 3: select delivery option
    $(document).on("click", ".delivery-radio-button", function(){
        // collapse
        $('#previewDeliveryCaption').html($(this).data("caption") + ' ' + $(this).data("price"));
        $('#collapseDelivery').collapse('hide');
        $('#previewDelivery').collapse('show');

        if($("#step4").hasClass("d-none")){
            $("#step4-spinner").removeClass("d-none");
            jump('#step3', {
                callback: () => {
                    $("#step4-spinner").remove();
                    $("#step4, #step5").removeClass("d-none").addClass("d-block");
                }
            })
        }
        //if($("#receipt_phone_number").val().length) $("#orderFormSubmitButton").removeAttr('disabled');
    });
    $(document).on('click', '#previewDelivery', function(){
        $('#collapseDelivery').collapse('show');
        $('#previewDelivery').collapse('hide');
    });

    // step 4: enter receipt phone number
    // $(document).on("keyup", "#receipt_phone_number", function(){
    //     if($(this).val().length > 1){
    //         $("#orderFormSubmitButton").removeAttr('disabled');
    //     }else{
    //         $("#orderFormSubmitButton").attr('disabled', true);
    //     }
    // });

    // step 6: submit
    $(document).on("submit", "#orderData", function(event){
        event.preventDefault();
        // Object Properties: content_ids, content_name, content_type, contents, currency, value
        if(typeof fbq != "undefined"){
            fbq('track', 'AddToCart', { content_ids: [item_id], currency: "EUR", value: item_value});
        }
        //$('#orderFormSubmitButton').prop('disabled', true);
        $('#orderFormSubmitButton').html("<span class=\"spinner-grow spinner-grow\" role=\"status\" aria-hidden=\"true\"></span>");
        fetch("../order/checkout", {
            method: "POST",
            body: new FormData(event.target),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (session) {
                var stripe = Stripe(session.publishable_key);
                return stripe.redirectToCheckout({sessionId: session.id});
            })
            .then(function (result) {
                // If redirectToCheckout fails due to a browser or network
                // error, you should display the localized error message to your
                // customer using error.message.
                if (result.error) {
                    alert(result.error.message);
                }
            })
            .catch(function (error) {
                console.error("Error:", error);
            });
    });

    // initiate date-picker
    $(document).on("click", ".date-picker-group", function(){
        $(".date-picker-group").hide();
        $($(".date-picker").data("container")).show();

    });

    // click on month controls
    $(document).on("click", ".calendar-button-month", function(){
        getCalendar($(this).data("container"), $(this).data("locale"), $(this).data("month"));
    });
});

// $( document ).on('turbolinks:load') fires every time page is loaded.
// Do not use this block to create event listeners. Otherwise you will assign new event listener ever time you load page.
$( document ).on('turbolinks:load', function() {
    // FB tracking
    if(typeof fbq != "undefined"){
        trackPageView();
    }

    if ($('#orderData').length > 0) {
        // fire FB event on product page
        if(typeof fbq != "undefined"){
            fbq('track', 'ViewContent', { content_type: 'product', content_ids: [$('#product_id').val()] });
        }

        getCalendar('.calendar-group', $("#locale").val(), $("#delivery_date").val());

        // display selected variant

        let variant = $("input[name='variant']:checked");
        if(variant.length > 0){
            if(variant.val().length > 0){
                $('#previewSizeCaption').html(variant.data("size") + ' ' + variant.data("price"));

                // display selected date
                let delivery_date = $("#delivery_date");
                let caption = $('#previewDateCaption');
                if(delivery_date.length > 0){
                    const date = new Date(delivery_date.val());
                    const options = {weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
                    caption.html(date.toLocaleDateString('lv-LV', options));
                }
                // display selected delivery variant
                let delivery = $("input[name='delivery_option']:checked");
                if(delivery.val().length > 0){
                    $('#previewDeliveryCaption').html(delivery.data("caption") + ' ' + delivery.data("price"));
                }
            }
        }
    }
});