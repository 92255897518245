// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascripts and only use these pack files to reference
// that code so it'll be compiled.

import $ from 'jquery'
import 'bootstrap'

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import './front.js'

//Include activestorage.js in your application's JavaScript bundle.
import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()
